(function($) {


  var Sage = {

    'common': {

      init: function() {

        /////////////////
        /// VARIABLES ///
        /////////////////

        var isPreloading = false;
        var themeIsLight = true;
        var webp = $('body').hasClass('webp');
        var menu = {
          menuIsOpen: false,
          menuIsAnimating: false,
          projectMenuIsOpen: false,
          projectMenuIsAnimating: false,
          logoIsAnimating: false,
          logoIsCondensed: false,
          logoLetters: $('#moinat_logo_large .letter'),
          animDelay: 10
        }

        function pixelRatio() {
          return (window.devicePixelRatio || 1) + 2.5;
        }

        function imgOptions() {
          return {
              dpr: pixelRatio(),
              format: webp ? 'webp' : 'jpg',
              responsive: true,
              width: 'auto',
              crop: 'scale',
              quality: 80,
              //effect: 'sharpen:' + sharpen,
          }
        }

        var waypointStaggerTimeout;
        var waypointStaggerElements = [];


        //////////////////
        /// CLOUDINARY ///
        //////////////////


        var cl = new cloudinary.Cloudinary({
          cloud_name: 'meta4',
          secure: true
        });

        function cldImage(name, options) {
          return cl.imageTag('moinat.wp/' + name, options).toHtml()
        }





        ////////////
        /// MENU ///
        ////////////

        function openMenu() {
          menu.menuIsAnimating = true;
          $('.header').css({
            'height': '100vh'
          });
          burgerState(false);
          headerColors('rgba(0,0,0,0.9)', 'rgb(255,255,255)', function(){
            menu.menuIsAnimating = false;
            menu.menuIsOpen = true;

            $('.nav-fullscreen').css({
              'display': 'block',
              'opacity': 0
            });
            anime({
              targets: '.nav-fullscreen',
              opacity: 1,
              duration: 300,
              easing: 'easeInOutCubic',
            })
          });
          showLogo();
        }

        function closeMenu() {
          var color = themeIsLight ? "rgb(0,0,0)" : "rgb(255,255,255)";
          menu.menuIsAnimating = true;
          anime({
            targets: '.nav-fullscreen',
            opacity: 0,
            duration: 400,
            easing: 'easeInOutCubic',
            complete: function(){
              burgerState(true);
              $('.nav-fullscreen').css({
                'display': 'none',
                'opacity': 0
              });
              headerColors('rgba(0,0,0,0)', color, function(){
                $('.header').css({
                  'height': 'initial'
                });
                menu.menuIsAnimating = false;
                menu.menuIsOpen = false;
                checkScroll();
              });
            }
          });
        }

        function headerColors(backgroundColor, buttonColor, callback) {
          anime({
            targets: '#moinat_logo_large',
            fill: buttonColor,
          })
          anime({
            targets: '.burger .stroke',
            backgroundColor: buttonColor
          })
          anime({
            targets: '.header',
            easing: 'easeOutQuad',
            duration: 300,
            backgroundColor: backgroundColor,
            complete: function(){
              callback();
            }
          });
        }

        function toggleMenu() {
          if(!menu.menuIsAnimating) {
            if(menu.menuIsOpen) {
              closeMenu();
            }else {
              openMenu();
            }
          }
        }

        function burgerState(state) {

          anime({
            targets: '.stroke:nth-child(1)',
            translateY: state ? 0 : -7,
            opacity: state ?  1 : 0,
            easing: 'easeInQuad',
            duration: 200,
            delay: state ? 110 : 0,
          });

          anime({
            targets: '.stroke:nth-child(2)',
            translateY: state ? -2 : -5,
            opacity: state ?  1 : 0,
            easing: 'easeInQuad',
            duration: 200,
            delay: state ? 55 : 110,
            //delay: 30
          });

          anime({
            targets: '.stroke:nth-child(3)',
            translateY: state ? -1 : -10,
            rotate: state ? 0 : -45,
            easing: 'easeInOutQuad',
            duration: 400,
            delay: state ? 0 : 220,
            //translateY: state ? 0 : -30
          });

        }

        function toggleProjectMenu() {
          var menuHeight = $('.projects-menu').outerHeight();
          if (!menu.projectMenuIsAnimating) {
            menu.projectMenuIsAnimating = true;
            if(!menu.projectMenuIsOpen) {
              menu.projectMenuIsOpen = true;
              anime({
                targets: '.projects-menu-container',
                height: (menuHeight + 60) + 'px',
                easing: 'easeInOutCubic',
                duration: 800,
                complete: function() {
                  menu.projectMenuIsAnimating = false;
                }
              });
              $('.projects-menu').css('display', 'block');
              $('.projects-menu li a').each(function(index) {
                anime({
                  targets: this,
                  //opacity: [0,1],
                  translateY: [160, 0],
                  skewY: [20, 0],
                  duration: 800,
                  easing: 'easeOutCubic',
                  delay: (index*60)
                })
              });
            }else {
              menu.projectMenuIsAnimating = true;
              $($('.projects-menu li a').get().reverse()).each(function(index) {
                anime({
                  targets: this,
                  translateY: [0, -160],
                  skewY: [0, -30],
                  duration: 800,
                  easing: 'easeOutCubic',
                  delay: (index*60)
                })
                anime({
                  targets: '.projects-menu-container',
                  height: '0px',
                  easing: 'easeInOutCubic',
                  duration: 800,
                  complete: function() {
                    menu.projectMenuIsAnimating = false;
                    menu.projectMenuIsOpen = false;
                  }
                });
              })
            }
          }

        }

        function showLogo() {
          if(menu.logoIsCondensed) {
            menu.logoIsAnimating = true;

            $(menu.logoLetters).each(function(index) {
              anime({
                targets: this,
                opacity: 1,
                delay: index*menu.animDelay,
                easing: 'easeInOutQuad',
                duration: 220,
                skewX: 0,
                translateX: 0
              })

            })
            setTimeout(function(){
              menu.logoIsAnimating = false;
              menu.logoIsCondensed = false;
            }, menu.logoLetters.length*menu.animDelay)
          }
        }

        function hideLogo() {

          if(!menu.logoIsCondensed && !menu.menuIsAnimating) {
            menu.logoIsAnimating = true;
            $($(menu.logoLetters).get().reverse()).each(function(index) {
              anime({
                targets: this,
                opacity: 0,
                delay: index*menu.animDelay,
                //delay: index*500,
                easing: 'easeInOutQuad',
                duration: 220,
                skewX: -5,
                translateX: -15
              })

            })
            setTimeout(function(){
              menu.logoIsAnimating = false;
              menu.logoIsCondensed = true;
            }, menu.logoLetters.length*menu.animDelay)
          }
        }

        function checkScroll() {
          if(!menu.logoIsAnimating && !menu.menuIsOpen) {
            if($(window).scrollTop() > 100) { hideLogo(); }
            else { showLogo(); }
          }
        }

        $(window).on('scroll', _.throttle(checkScroll, 100));

        $('.burger').on('click', function(){
          toggleMenu();
        });

        $('.expand-work').on('click', toggleProjectMenu);

        $(window).on('keydown', function(e) {
          if(e.key == 'Escape') {
            toggleMenu();
          }
        })


        /////////////////
        /// WAYPOINTS ///
        /////////////////

        function staggerAnimation() {
          clearTimeout(waypointStaggerTimeout);
          waypointStaggerTimeout = setTimeout(function(){
            $(waypointStaggerElements).each(function(index){
              anime({
                targets: this,
                duration: 1300,
                easing: 'easeOutExpo',
                opacity: [0, 1],
                skewY: [1, 0],
                translateY: [20, 0],
                delay: 250 + (index * 100)
              })
            });
            waypointStaggerElements = [];
          }, 50);
        }


        ///////////////////
        /// INIT SCRIPS ///
        ///////////////////

        function bodyClasses() {
          console.log($('classes').attr('class'));
          $('body').attr('class', $('classes').attr('class'));
          $('classes').remove();
        }

        function setPageTheme() {
          var theme = $('fields').data('theme');
          if(theme) {
            if(theme === "light") {
              themeIsLight = true;
              themeColors(true);
            }
            if(theme === "dark") {
              themeIsLight = false;
              themeColors(false);
            }
          }else {
            themeColors(true);
            themeIsLight = true;
          }
        }

        function themeColors(light) {
          var color = light ? "rgb(0,0,0)" : "rgb(255,255,255)";

          headerColors('rgba(0,0,0,0)', color, function(){});

          //BACKGROUND COLOR
          anime({
            targets: 'body',
            backgroundColor: light ? '#ffffff' : '#000000',
            color: light ? "#000" : "#fff"
          })

          //FONT COLOR
          anime({
            targets: ['.main a', '.main p', 'footer a', 'footer p'],
            color: light ? "#000" : "#fff"
          })

          //FOOTER SVG
          anime({
            targets: 'footer #moinat_logo_small',
            fill: light ? "#000" : "#fff"
          })

          //PRELOADER
          anime({
            targets: '.global-preloader #loading-bar',
            backgroundColor: light ? "#000" : "#fff"
          })

        }

        function preloading(bool) {
          if(bool) {
            $('.global-preloader').css('display', 'block');
            anime({
              targets: '.global-preloader',
              opacity: [0, 1],
              easing: 'easeOutExpo'
            });
          }
          else {
            anime({
              targets: '.global-preloader',
              opacity: [1, 0],
              complete: function() {
                $('.global-preloader').css('display', 'none');
              }
            });
          }
        }

        function updatePreloader(percent) {
          anime({
            targets: '#loading-bar',
            scaleX: percent,
            easing: 'easeOutExpo',
            duration: 2000
          })
        }

        function finishedPreloading($container) {
          if(isPreloading) {
            isPreloading = false;
            setTimeout(function(){
              preloading(false);
              updatePreloader(0);
              pageLoadAnimation($container);
              $($container).css('opacity', 1);
            }, 500)
          }
        }

        function pageLoadAnimation(container) {
          setPageTheme();
          $(container).css({
            'visibility': 'visible',
            'opacity': '1'
          });
          anime({
            targets: 'footer',
            opacity: [0, 1],
            easing: 'easeOutExpo',
            delay: 250
          });
          initialize();
        }

        function preloadImages($container) {
          cloudinaryImages();
          isPreloading = true;
          preloading(true);
          updatePreloader(0.1);
            $container.imagesLoaded().
            progress(function(instance, image){
              updatePreloader(instance.progressedCount / instance.images.length);
              if(instance.progressedCount == instance.images.length) {
                finishedPreloading($container);
              }
              if(instance.images.length == 0) {
                finishedPreloading($container);
              }
            }).always(function(instance){
                if(instance.images.length == 0) {
                  finishedPreloading($container);
                }
            })
            .done(function(){
              //finishedPreloading($container);
            })
        }

        function cloudinaryImages() {
          $('.cld-responsive').each(function(){
            var parent = $(this).parent();
            var data = $(this).data();
            var img = cldImage(data.name, imgOptions());
            $(parent).find('img').remove();
            $(parent).append(img);
            $(parent).find('img').addClass('cld-responsive');
          });

          cl.responsive();
        }

        function initialize() {

          bodyClasses();

          $('.waypoint-stagger').css('opacity', 0);

          var waypointAppear = $('.waypoint-stagger').waypoint({
             offset: function() {return $(window).innerHeight() },
            handler: function(direction) {
              waypointStaggerElements.push(this.element);
              if(direction == "down") {
                staggerAnimation();
              }
              this.destroy();
            }
          });

        }

        //FIRST LOAD
        preloadImages($('.main'));



        /////////////////////////
        /// BARBA + PRELOADER ///
        /////////////////////////

        Barba.Pjax.start();

        var PageTransition = Barba.BaseTransition.extend({
          start: function(){
            Promise
            .all([this.newContainerLoading,this.fadeOut()])
            .then(this.fadeIn.bind(this));
          },

          fadeOutAnimation: function(promise){
            Waypoint.destroyAll();
            anime({
              targets: [this.oldContainer, 'footer'],
              opacity: [1, 0],
              easing: 'easeOutExpo',
              complete: function(){
                $('body, html').scrollTop(0);
                promise.resolve();
              }
            });
          },

          fadeOut: function(){
            var deferred = Barba.Utils.deferred();
            closeMenu();
            this.fadeOutAnimation(deferred);
            return deferred.promise;
          },

          fadeIn: function(){
            var $newContainer = $(this.newContainer);
            $newContainer.css('opacity', 0);
            this.done();
            preloadImages($newContainer);
          },
        });

        Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

        Barba.Pjax.getTransition = function() {
          return PageTransition;
        };



      },
      finalize: function() {

      }
    }
  };
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
